<template src="./DetailAnnouncementStudent.html"></template>

<script>
import notificationsService from "../../services/Notifications";
import ChosenUniversity from "../../components/ChosenUniversity/ChosenUniversity";
import announcementService from "../../services/Announcement";
import tutorial from "@/mixins/tutorial";

export default {
  name: "DetailAnnouncementStudent",
  mixins: [tutorial],
  components: {
    ChosenUniversity,
  },
  data() {
    return {
      announcement: {
        announcement: {
          name: "",
          institution: { name: "" },
          description: "",
          category: { name: "" },
          dateInit: "",
          dateFinish: "",
        },
      },
      role: "",
      myParticipatingGroups: { groupAnnouncements: [] },
      showViewMore: false,
      modalShareAnnouncement: false,
    };
  },
  created() {
    this.role = this.$store.state.User.user.role.name;
    this.getSpecificAnnouncement();
  },
  mounted() {
    this.dataTutorial("detailAnnouncement");
  },
  methods: {
    showModalShareAnnouncement() {
      this.modalShareAnnouncement = true;
    },
    async share() {
      try {
        const infoShare = {
          title: "Te han compartido una convocatoria",
          body: this.announcement.announcement.name,
          // TO DO servicio mal estructurado no deberia enviarse el id de la notificacion quemado
          typeId: "6ee66b47-641d-4d24-ad90-5e4264d27069",
        };
        await notificationsService.shareAnnouncement(infoShare);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Convocatoria compartida",
          position: "is-bottom",
          type: "is-success",
        });
        this.modalShareAnnouncement = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getSpecificAnnouncement() {
      try {
        this.announcement = await announcementService.getSpecificAnnouncement(
          this.$route.params.id
        );
        const infoGetMygroups = {
          state: true,
          announcementId: this.announcement.announcement.id,
        };
        if (this.role !== "Entrepreneur") {
          this.myParticipatingGroups =
            await announcementService.getMyParticipatingGroups(infoGetMygroups);
        }
      } catch (e) {
        console.error("error", e);
      }
    },
  },
  computed: {
    colorByStatus() {
      return (status) => {
        switch (status) {
          case false:
            return "dotRed";
          case true:
            return "dotGreen";
          default:
            null;
            return "dotYellow";
        }
      };
    },
    nameByStatus() {
      return (status) => {
        switch (status) {
          case false:
            return "Rechazado";
          case true:
            return "Aceptado";
          default:
            null;
            return "En evaluación";
        }
      };
    },
    roleValidationUniversity() {
      return ["University"].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./DetailAnnouncementStudent.scss"></style>